import React from "react";
import '../privacy.css';  // Make sure to create or update this CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      
      {/* Main Content Section */}
      <main className="privacy-content container">

        {/* Definitions Section */}
        <section className="privacy-section mb-4">
          <h2>Definitions</h2>
          <p>
            <strong>"Controller"</strong> means The App Design, the organization that determines the purposes and means of processing Personal Data.
          </p>
          <p>
            <strong>"Processor"</strong> means any third party that processes Personal Data on behalf of the Controller.
          </p>
          <p>
            <strong>"Personal Data"</strong> means any information relating to an identified or identifiable natural person, such as name, email address, phone number, IP address, or other online identifier, as well as any other information that is linked or linkable to an identified or identifiable natural person.
          </p>
        </section>

        {/* General Section */}
        <section className="privacy-section mb-4">
          <h2>General</h2>
          <p>
            At The App Design, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and protect your personal information in connection with our website, services, products, and any related software, documentation, and other materials provided by us (collectively, the "Services"). We collect personal information from you when you submit a request for services through our website or email, and such collection is based on our legitimate interest in providing our services, improving our service, and customer relationship management (CRM).
          </p>
          <p>
            By using our Services, you consent to the collection, use, disclosure, and protection of your personal information in accordance with this Privacy Policy. If you do not agree with any of the terms of this Privacy Policy, please do not use our Services.
          </p>
        </section>

        {/* Collection and Use of Personal Information Section */}
        <section className="privacy-section mb-4">
          <h2>Collection and Use of Personal Information</h2>
          <p>
            We may collect personal information from you in connection with your use of our Services. Personal information is information that identifies or relates to you as an individual. The types of personal information we may collect include:
          </p>
          <ul>
            <li>Contact information, such as your name, email address, mailing address, and phone number;</li>
            <li>Usage information, such as your IP address, browser type, operating system, and pages visited on our website;</li>
            <li>Communications, such as messages you send to us or information you provide when you participate in a survey or promotion;</li>
            <li>Other information you choose to provide to us.</li>
          </ul>
          <p>
            We use the personal information we collect for the following purposes:
          </p>
          <ul>
            <li>To provide our Services to you;</li>
            <li>To communicate with you about our Services and respond to your inquiries;</li>
            <li>To personalize your experience on our website and tailor our Services to your interests;</li>
            <li>To conduct research and analysis to improve our Services;</li>
            <li>To comply with legal obligations and protect our rights and interests;</li>
            <li>For any other purpose with your consent.</li>
          </ul>
          <p>
            We may also use your contact information to send you marketing materials about our products, services, and promotions. If you do not wish to receive these materials, you can unsubscribe at any time by clicking the "unsubscribe" link in the email or by contacting us.
          </p>
        </section>

        {/* Disclosure of Personal Information Section */}
        <section className="privacy-section mb-4">
          <h2>Disclosure of Personal Information</h2>
          <p>
            We may disclose your personal information to the following types of third parties:
          </p>
          <ul>
            <li>Service Providers: We may share your personal information with third-party service providers who help us operate our business, such as website hosts, and marketing partners. These service providers may use your personal information only as necessary to provide their services to us and are contractually obligated to protect your personal information.</li>
            <li>Affiliates: We may share your personal information with our affiliates for the purposes described in this Privacy Policy.</li>
            <li>Business Partners: We may share your personal information with our business partners when we collaborate with them to provide you with certain products or services.</li>
            <li>Legal Requirements: We may disclose your personal information when we believe in good faith that such disclosure is necessary to comply with applicable laws, regulations, or legal processes, or to respond to a subpoena or court order.</li>
            <li>Protect Our Rights and Interests: We may disclose your personal information when we believe in good faith that such disclosure is necessary to protect our rights or interests or the rights or interests of others.</li>
          </ul>
          <p>
            We do not sell, rent, or lease your personal information to third parties.
          </p>
        </section>

        {/* Third-Party Links Section */}
        <section className="privacy-section mb-4">
          <h2>Third-Party Links</h2>
          <p>
            Our Services may contain links to third-party websites, products, and services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies and terms of use of any third-party sites that you access.
          </p>
          <p>
            You further acknowledge and agree that The App Design shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
          </p>
        </section>

        {/* Security Section */}
        <section className="privacy-section mb-4">
          <h2>Security</h2>
          <p>
            We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage can be guaranteed to be completely secure. We cannot guarantee the security of your personal information and disclaim any liability for unauthorized access to or use of your personal information.
          </p>
        </section>

        {/* GDPR Section */}
        <section className="privacy-section mb-4">
          <h2>General Data Protection Regulation (GDPR)</h2>
          <p>
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights. The App Design is committed to complying with the General Data Protection Regulation (GDPR) and other applicable data protection laws.
          </p>
          <ul>
            <li>Right of access to Your Personal Data. You can access, update, or request the deletion of Your Personal Data.</li>
            <li>Right to request rectification of Personal Data that We hold about You.</li>
            <li>Right to restriction of Processing of Personal Data.</li>
            <li>Right to object to Processing of Your Personal Data.</li>
            <li>Right to request the erasure of Your Personal Data (“right to be forgotten”).</li>
            <li>Right to request the transfer of Your Personal Data (“right to data portability”).</li>
            <li>Right to withdraw Your Consent.</li>
          </ul>
          <p>
            To exercise any of these rights, please contact us using the information provided below. We will respond to your request within a reasonable timeframe.
          </p>
        </section>

        {/* NJDPA Section */}
        <section className="privacy-section mb-4">
          <h2>NJ Data Privacy Act (NJDPA)</h2>
          <p>
            If you are a New Jersey resident, you have the right to request that we disclose certain information to you about our collection, use, disclosure, and sale of your personal information over the past 12 months.
          </p>
          <p>
            We do not sell personal information of NJ consumers, and we have not sold such information in the preceding 12 months.
          </p>
        </section>

        {/* COPPA Section */}
        <section className="privacy-section mb-4">
          <h2>Children's Online Privacy Protection Act (COPPA)</h2>
          <p>
            Our Services are not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are under 13 years of age, do not use or provide any information on our Services or provide any personal information about yourself to us.
          </p>
        </section>

        {/* Contact Us Section */}
        <section className="privacy-section mb-4">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@theappdesign.com">info@theappdesign.com</a>.
          </p>
        </section>

      </main>
    </div>
  );
};

export default PrivacyPolicy;

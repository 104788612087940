// RightImageSection.js
import React from 'react';
import rightimg01 from '../images/cutting.png'; // Adjust based on your file's format

const ServiceRightSec = () => {
  return (
    <section className="right-image-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Left side: Heading and Description */}
          <div className="col-md-6">
            <img src={rightimg01} alt="Right Image" className="img-fluid" />
          </div>

          {/* Right side: Text Content */}
          <div className="col-md-6">
            <h2 className="section-heading">Our Process</h2>
            <p className="section-description">
              <strong>Discover:</strong> Initially, we want to understand what you need. Let's talk about what problem your app is solving, the target audience, and the unique features you envision. This becomes the very first phase to align all of our efforts toward achieving your goals.
            </p>
            <p className="section-description">
              <strong>Define:</strong> We will scope out the project, features, and end-user experience for the apps after clearly understanding your vision. To visualize the app's design and functionality, we will create detailed specifications, wireframes, and mockups.
            </p>
            <p className="section-description">
              <strong>Develop:</strong> Once we arrive at the final design, our talented developers will realize the application, write the code, test it extensively, and fine-tune it for maximum performance and user experience.
            </p>
            <p className="section-description">
              <strong>Deploy:</strong> After thorough testing, we will release your app in the corresponding app stores or platforms. We will handle all the technicalities regarding app store submissions, set-up, and distribution for the launch.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceRightSec;

// Import necessary modules and components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import '../ServicesSwap.css';
import ServicesSwap1 from '../images/ios&andriod.png'; 
import ServicesSwap2 from '../images/eco.webp'; 
import ServicesSwap3 from '../images/webapp.webp'; 
import ServicesSwap4 from '../images/flutter.webp'; 
import ServicesSwap5 from '../images/cross.webp'; 


const slides = [
  {
    img: ServicesSwap1 , // Replace with your image path
    title: 'iOS & Android',
  },
  {
    img: ServicesSwap5 , // Replace with your image path
    title: 'Cross-Platform',
  },
  {
    img: ServicesSwap4 , // Replace with your image path
    title: 'Flutter',
  },
  {
    img: ServicesSwap2 , // Replace with your image path
    title: 'E-Commerce',
  },
  {
    img: ServicesSwap3 , // Replace with your image path
    title: 'Web App',
  },
  // Add more slides as necessary
];

const ServicesWeDeliver = () => {
  return (
    <section className="services-we-deliver py-5 position-relative">
      <div className="container">
        <div className="text-center">
          <h2>Browse The Full Range of Our Services</h2>
          <p className="description-swap">
            All the solutions you're looking for are available in our complete portfolio of services.<br />
            We are your partner in innovation from concept to completion.
            </p>

        </div>
        <div className="mx-5">
        
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".slider-button-next",
              prevEl: ".slider-button-prev",
            }}
            loop
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 30 },
            }}
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={i}>
                <div className="services-slide">
                  <img src={slide.img} alt={slide.title} className="img-fluid" />
                  <h3 className="mt-3 text-center">{slide.title}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ServicesWeDeliver;

import React, { useState } from 'react';
import '../App.css';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import ContactSection from '../components/form';
import FAQSection from '../components/faqs';
import BreadcrumbSection from '../components/BreadCrumb';
import aboutusbc from '../images/aboutusbc.png';
import LeftSection from '../components/leftcontent';
import RightSection from '../components/rightsection';
import WhyChooseSec from '../components/WhyChoose';

import { Helmet } from 'react-helmet-async';
import RightImageSection from '../components/rightImage';
import AppDevValuesSection from '../components/AppDevValues';


import { Link } from 'react-router-dom';
import PopupForm1 from '../components/PopupForm';


const About = () => {

    // ******************************** Start FAQ Section ******************************
    const faqData = [

        {
            question: "How much does it cost to design a mobile app?",
            answer: "Designing a mobile app can vary from $5,000 to $50,000 depending on several factors. A straightforward mobile app will usually be cheaper compared to high-end features."
        },
        {
            question: "How can I design a mobile app?",
            answer: "A mobile app can be designed, defining its purpose, wireframes created, UI/UX designed, the app developed, and finally, it can be tested. Expert teams work effectively and bring even higher-quality results."
        },
        {
            question: "What is mobile app UI UX design?",
            answer: "This includes creating attractive screens, making it easy for the user to navigate through the application and enjoy using it. Mobile app UI/UX design enables effective user interface (UI) design and overall user experience (UX) enhancement."
        },
        {
            question: "Who designs phone apps?",
            answer: "Designers design apps and developers work in app development. Usually, app-designing teams consist of designers and developers. Designers only focus on the UI and UX to be simplistic, while developers make the whole functionality of the application, whereby catering for programming and coding."
        },
        {
            question: "How to design UI UX for an Android app?",
            answer: "To design UI/UX for an Android app, create wireframes, follow Design principles, and test for usability across devices."
        }
    ];

    // ******************************** End FAQ Section ****************************** 

    return (
        <>
            <HeaderSection />
            <Helmet>
                <title>About Us | The App Design</title>
                <meta name="description" content="The App Design established in 2016, is a full-cycle app development company specializing in creating custom app solutions for businesses globally." />
                <link rel="canonical" href="https://theappdesign.com/about-us" />
            </Helmet>

            <div>
                <BreadcrumbSection
                    heading="About Us"
                    description="Top-rated mobile app development company.Let's create a mobile app <br/>that your clients will enjoy and will move your business forward."
                    buttonText="Get Started"
                    backgroundImage={aboutusbc}
                />
            </div>
            <RightSection />
            <LeftSection />
            <WhyChooseSec />
            <RightImageSection />
            <AppDevValuesSection />
            <div>
                <FAQSection faqData={faqData} />
            </div>
            <ContactSection />
            <FooterSection />
            <PopupForm1/>

        </>
    );
};

export default About;

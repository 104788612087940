import React from 'react';
import '../App.css';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import ContactSection from '../components/form';
import FAQSection from '../components/faqs';
import BreadcrumbSection from '../components/BreadCrumb';
import contactUsbc from '../images/contactbc.png';
import UniqueSection from '../components/unique';
import ContactUsSec from '../components/contactform';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PopupForm1 from '../components/PopupForm';


const About = () => {

// ******************************** Start FAQ Section ******************************
const faqData = [

  {
    question: "How much does it cost to design a mobile app?",
    answer: "Designing a mobile app can vary from $5,000 to $50,000 depending on several factors. A straightforward mobile app will usually be cheaper compared to high-end features."
  },
  {
    question: "How can I design a mobile app?",
    answer: "A mobile app can be designed, defining its purpose, wireframes created, UI/UX designed, the app developed, and finally, it can be tested. Expert teams work effectively and bring even higher-quality results."
  },
  {
    question: "What is mobile app UI UX design?",
    answer: "This includes creating attractive screens, making it easy for the user to navigate through the application and enjoy using it. Mobile app UI/UX design enables effective user interface (UI) design and overall user experience (UX) enhancement."
  },
  {
    question: "Who designs phone apps?",
    answer: "Designers design apps and developers work in app development. Usually, app-designing teams consist of designers and developers. Designers only focus on the UI and UX to be simplistic, while developers make the whole functionality of the application, whereby catering for programming and coding."
  },
  {
    question: "How to design UI UX for an Android app?",
    answer: "To design UI/UX for an Android app, create wireframes, follow Design principles, and test for usability across devices."
  }
];

// ******************************** End FAQ Section ******************************



    return (
      <>
        <HeaderSection />
        <Helmet>
          <title>Contact Us | The App Design</title>
          <meta name="description" content="Get in touch with The App Design to tell us more about your project, expectations, and software development needs." />
          <link rel="canonical" href="https://theappdesign.com/contact-us" />
        </Helmet>
        <div>
       <BreadcrumbSection
        heading="Contact Us"
        description="A place where talent and ambition can soar. Unleash your creativity and let your talents take center stage.  <br />When you work with us, you step into a world where your passion meets our purpose."
        buttonText="Get Started"
        buttonLink="#"
        backgroundImage={contactUsbc}
      />
        </div>

        <ContactUsSec/>

        <div>
         <FAQSection faqData={faqData} />
        </div>
        <FooterSection />

        <PopupForm1/>


      </>
    );
  };
  
  export default About;

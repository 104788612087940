import React, { useState } from "react";
import "../App.css"; // Create a CSS file for custom styling
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ContactSection = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); // Loading state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    })

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateEmailAndPhone = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            phoneRegex = /^\+1\d{10}$/

        if (!emailRegex.test(formData.email))
            document.querySelector('.get-started-section input[name=email]').classList.add('is-invalid')
        else
            document.querySelector('.get-started-section input[name=email]').classList.remove('is-invalid')

        if (!phoneRegex.test(formData.phone))
            document.querySelector('.get-started-section input[name=phone]').classList.add('is-invalid');
        else
            document.querySelector('.get-started-section input[name=phone]').classList.remove('is-invalid');

        return phoneRegex.test(formData.phone) && emailRegex.test(formData.email)
    }


    const handleSubmit = async e => {
        e.preventDefault()

        // Email & phone validation
        if (!validateEmailAndPhone())
            return

        setLoading(true)

        await fetch("https://theappdesign.com/php_mailer/index.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then(r => {
                if (!r.ok) {
                    throw new Error(`HTTP error! status: ${r.status}`);
                }
                return r.json();
            })
            .then(({ status, message }) => {
                setLoading(false);
                if (status === 'success') {
                    navigate('/thank-you');
                } else {
                    Swal.fire('Error', message, 'error');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
                Swal.fire('Error', 'Failed to submit the form. Please try again later.', 'error');
            });

    }

    return (
        <section className="custom-contact-section py-5 get-started-section" id="contact">
            <div className="container py-5 px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                    <h2 className="">Get In Touch!</h2>
                </div>
                <div className="row justify-content-center"> 
                    <div className="col-lg-9">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <p className="text-center mb-4">Have questions, or are you ready to start your project? Reach out to us!</p>
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Your Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                req
                                            />

                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="subject"
                                                placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>



                                    <div className="col-md-6  ">
                                        <div className="form-floating">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Contact Number*"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="phone">Phone</label>
                                            <div className="invalid-feedback">
                                                Invalid Phone number. Example: +19876543210
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Leave a message here"
                                                name="message"
                                                style={{ height: "150px" }}
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        {/* <button
                      className="btn btn-primary-gradient rounded-pill py-3 px-5"
                      type="submit"
                    >
                      Send Message
                    </button> */}


                                        <button type="submit" className="btn btn-primary-gradient rounded-pill py-3 px-5" disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                                    <span role="status">Submitting...</span>
                                                </>
                                            ) : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;

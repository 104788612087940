import React from "react";
import Slider from "react-slick"; // Import the react-slick Slider
import "slick-carousel/slick/slick.css"; // Slick carousel CSS
import "slick-carousel/slick/slick-theme.css"; // Slick carousel theme CSS
import "../Mobileapp.css"; // Your custom CSS
import client1 from "../images/client1.png";
import client2 from "../images/client2.png";
import client3 from "../images/client3.jpg";


const Testimonials = () => {
  // Settings for the slider
  const sliderSettings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1, // Scroll 3 slides at a time
    autoplay: true, // Auto-play the slides
    autoplaySpeed: 3000, // Speed of auto-play
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          slidesToShow: 1, // Show 2 slides at a time
          slidesToScroll: 2, // Scroll 2 slides at a time
        },
      },
      {
        breakpoint: 600, // For screens smaller than 600px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
        },
      },
    ],
  };

  return (
    <section className="mobile-apps-faq">
      <div className="container-xxl" id="review">
        <div className="container py-5 px-lg-5">
          <div className="text-center">
            <h5 className="text-primary-gradient fw-medium">Testimonial</h5>
            <h2 className="mb-5">What Say Our Clients!</h2>
          </div>
          <Slider {...sliderSettings} className="testimonial-carousel">
            {/* Testimonial Item 1 */}
            <div className="testimonial-item rounded p-4">
              <div className="d-flex align-items-center mb-4">
              <img
                className="img-fluid bg-white rounded flex-shrink-0 p-1"
                src={client1}
                alt="Client 1"
                style={{ width: "85px", height: "85px" }}
              />
                <div className="ms-4">
                  <h5 className="mb-1">Mark Chambers</h5>
                  {/* <p className="mb-1">Profession</p> */}
                  <div>
                    {[...Array(5)].map((_, index) => (
                      <small key={index} className="fa fa-star text-warning"></small>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-0">
              We chose The App Design for its proven expertise and high-quality standards. The app exceeded 
              our expectations with its user-friendly design and functionality. Within two weeks, we saw 
              500 downloads and received positive user feedback.
              </p>
            </div>
            {/* Testimonial Item 2 */}
            <div className="testimonial-item rounded p-4">
              <div className="d-flex align-items-center mb-4">
              <img
                className="img-fluid bg-white rounded flex-shrink-0 p-1"
                src={client2}
                alt="Client 2"
                style={{ width: "85px", height: "85px" }}
              />
                <div className="ms-4">
                  <h5 className="mb-1">Kevin Philip</h5>
                  {/* <p className="mb-1">Profession</p> */}
                  <div>
                    {[...Array(5)].map((_, index) => (
                      <small key={index} className="fa fa-star text-warning"></small>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-0">
              The App Design showed great interest in our project, delivering quality results at a cost-effective price. 
              They created a scalable MVP with excellent project management and a passionate team. We’re thrilled with 
              the outcome!
              </p>
            </div>
            {/* Testimonial Item 3 */}
            <div className="testimonial-item rounded p-4">
              <div className="d-flex align-items-center mb-4">
              <img
                className="img-fluid bg-white rounded flex-shrink-0 p-1"
                src={client3}
                alt="Client 3"
                style={{ width: "85px", height: "85px" }}
              />
                <div className="ms-4">
                  <h5 className="mb-1">Elias Dullens</h5>
                  {/* <p className="mb-1">Profession</p> */}
                  <div>
                    {[...Array(5)].map((_, index) => (
                      <small key={index} className="fa fa-star text-warning"></small>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-0">
              I had a great experience working with The App Design. Their impressive interface designs, consistent communication, 
              and strong programming made the project a success. I look forward to working with them again!
              </p>
            </div>
            {/* Additional Testimonial Items */}
            {/* <div className="testimonial-item rounded p-4">
              <div className="d-flex align-items-center mb-4">
              <img
                className="img-fluid bg-white rounded flex-shrink-0 p-1"
                src={client3}
                alt="Client 3"
                style={{ width: "85px", height: "85px" }}
              />
                <div className="ms-4">
                  <h5 className="mb-1">Client Name</h5>
                  <p className="mb-1">Profession</p>
                  <div>
                    {[...Array(5)].map((_, index) => (
                      <small key={index} className="fa fa-star text-warning"></small>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div> */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

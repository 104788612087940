import React from 'react';
import '../App.css';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import TermsAndConditions from '../components/TermsOfUse';
import BreadcrumbSection from '../components/BreadCrumb';
import termsbc from '../images/terms.png';



import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Process = () => {



    return (
      <>
        <HeaderSection />
        <Helmet>
          <title>Terms and Conditions | The App Design</title>
          <meta name="description" content="Rules and regulations that are applied at TheAppDesign’s website. Make sure to read before using our website to avoid any misunderstanding." />
          <link rel="canonical" href="https://theappdesign.com/terms-and-conditions" />
        </Helmet>
        
        <div>
       <BreadcrumbSection
        heading="The App Design - Terms of Use"
        description="A place where talent and ambition can soar. Unleash your creativity and let your talents take center stage.  <br />When you work with us, you step into a world where your passion meets our purpose."
        buttonText="Learn More"
        buttonLink="#"
        backgroundImage={termsbc}
      />
        </div>
        <div>
        <TermsAndConditions />
        </div>
        <FooterSection />

        
      </>
    );
  };
  
  export default Process;

// RightImageSection.js
import React from 'react';
import "../rightimg.css";
import rightimg01 from '../images/our process.jpg'; // Adjust based on your file's format


const RightImageSection = () => {
  return (
    <section className="right-image-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Left side: Heading and Description */}
          <div className="col-md-6">
            <img src={rightimg01} alt="Right Image" className="img-fluid" />
          </div>

          {/* Right side: Image */}
          

          <div className="col-md-6">
            <h2 className="section-heading">A Culture of Diversity and Inclusive Teams</h2>
            <p className="section-description">
            The App Design is a place where diverse talents converge to learn, innovate, and thrive. 
            Our teams consist of passionate and creative individuals from all backgrounds, bringing 
            unique perspectives to everything we do. We foster an inclusive culture that celebrates 
            diversity, fuels collaboration, and empowers our people to deliver exceptional results 
            for our clients.
            </p>
            <p className="section-description">
            This commitment to diversity and inclusion not only strengthens our teams but also 
            inspires groundbreaking solutions. At The App Design, we believe that our differences 
            make us stronger, driving innovation and success in every endeavor.
            </p>
            <button data-bs-toggle='modal' data-bs-target='#popupForm' className="btn btn-primary">Get Started</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightImageSection;
